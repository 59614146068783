import styled from "styled-components";
import { Rem, BP } from "../../commons/Theme";

export const StyledContainerCouponCode = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  z-index:1;
  text-align: center;
  font-size: ${Rem(24)};
  flex-wrap: wrap;
  margin-bottom: ${Rem(60)};
`;

export const StyledContainerCouponCodeTitle = styled.div`
  width: 100%;
  margin-bottom: ${Rem(24)};
`

export const StyledFormCouponCode = styled.form`
`

export const StyledImageCouponCode = styled.div`
  max-width: 400px;
  width: 97%;
  align-self: center;
`

export const StyledContainerBeeBoxStore = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  padding: 10px;
  @media (${BP.ipad}) {
    flex-direction: row;
    margin-top: ${Rem(120)};
  }
  

`

